import '../../theme/datepicker.css'

import styled from '@emotion/styled'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Input } from 'app/components/Common/Input'
import { Select } from 'app/components/Common/Select'
import { Separator } from 'app/components/Common/Separator'
import { Spinner } from 'app/components/Common/Spinner'
import { Textarea } from 'app/components/Common/Textarea'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { EventsFormSenderBackend, FormData } from 'app/utils/EventsFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { enUS, it } from 'date-fns/esm/locale'
import { rgba } from 'emotion-rgba'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useForm } from 'react-hook-form'

registerLocale('en', enUS)
registerLocale('it', it)

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  description?: string
  languageCode: string
  languagePrefix: string
  onEventsFormSendToBackend: EventsFormSenderBackend
}

export const EventsForm = memo(function EventsForm({
  description,
  languageCode,
  languagePrefix,
  onEventsFormSendToBackend,
}: Props) {
  /*
   * Datepicker configuration
   */
  const [date, setDate] = useState<Date>(new Date())
  const [dateValue, setDateValue] = useState('')

  /*
   * Form data
   */
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onEventsFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)
        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'Submit', {
            event_category: 'Website',
            event_label: 'Events Form',
          })
      }
    },
    [isSendingForm, onEventsFormSendToBackend],
  )

  const { register, formState, handleSubmit, setValue } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */
  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && (firstErrorElement as any) instanceof Element) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }

    if (dateValue) {
      setValue('date', dateValue)
    }
  }, [formState.errors, dateValue])

  return (
    <Container>
      <Separator />
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      <Form
        onSubmit={isSendingForm ? undefined : onSubmit}
        noValidate
        autoComplete={'off'}
      >
        <Spinner className={isSendingForm ? '' : 'hidden'} />
        <Wrapper row wrap space="between">
          <Field>
            <Label>{`${useVocabularyData('firstname', languageCode)} *`}</Label>
            <Input
              error={formState.errors.name !== undefined}
              isDirty={formState.dirtyFields.name}
              type="text"
              required={true}
              {...register('name', {
                required: true,
                validate: (name) => name.length > 2,
              })}
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('lastname', languageCode)} *`}</Label>
            <Input
              error={formState.errors.lastname !== undefined}
              type="text"
              required={true}
              {...register('lastname', {
                required: true,
                validate: (lastname) => lastname.length > 2,
              })}
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('email', languageCode)} *`}</Label>
            <Input
              error={formState.errors.email !== undefined}
              isDirty={formState.dirtyFields.email}
              type="email"
              required={true}
              {...register('email', {
                required: true,
                validate: (email) => EMAIL_REGEX.test(email),
              })}
            />
          </Field>
          <Field>
            <Label>{useVocabularyData('telephone', languageCode)}</Label>
            <Input type="text" {...register('phone')} />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('date', languageCode)} *`}</Label>
            <DatePicker
              className={
                formState.errors.date !== undefined ? 'error' : undefined
              }
              dateFormat="dd/MM/yyyy"
              disabledKeyboardNavigation={true}
              locale={languagePrefix || 'en'}
              {...register('date', {
                required: true,
              })}
              onChange={(date) => {
                if (date) {
                  setDate(date)
                  setDateValue(
                    date.toLocaleDateString('it-IT', {
                      day: 'numeric',
                      month: '2-digit',
                      year: 'numeric',
                    }),
                  )
                }
              }}
              onFocus={(e) => (e.target.readOnly = true)}
              minDate={new Date()}
              required={true}
              selected={date}
              withPortal={
                typeof window !== 'undefined' && window.innerWidth > 767
                  ? false
                  : true
              }
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('n-pax', languageCode)} *`}</Label>
            <Select
              error={formState.errors.pax !== undefined}
              options={[
                { value: '1' },
                { value: '2' },
                { value: '3' },
                { value: '4' },
                { value: '5' },
                { value: '5+' },
              ]}
              required={true}
              {...register('pax', {
                required: true,
                validate: (pax) => pax != '',
              })}
            />
          </Field>
          <Field className="full-width">
            <Label>{useVocabularyData('message', languageCode)}</Label>
            <Textarea required={false} {...register('message')} />
          </Field>
          <Checkbox
            error={formState.errors.privacy_policy !== undefined}
            label={useVocabularyData('privacy-policy-text', languageCode)}
            required={true}
            {...register('privacy_policy', {
              required: true,
            })}
          />
          {displaySendErrorFeedback ? (
            <FormMessages
              text={useVocabularyData('form-error-message', languageCode)}
              title={useVocabularyData(
                'form-error-message-title',
                languageCode,
              )}
              type="error"
            />
          ) : null}
          {displaySendConfirmFeedback ? (
            <FormMessages
              text={useVocabularyData(
                'form-confirmation-message',
                languageCode,
              )}
              title={useVocabularyData(
                'form-confirmation-message-title',
                languageCode,
              )}
            />
          ) : null}
          <Input
            name="submit"
            type="submit"
            value={useVocabularyData('request-info', languageCode)}
            variant="submit"
          />
        </Wrapper>
      </Form>
    </Container>
  )
})

const Container = styled.section`
  max-width: 67.25rem;
  margin: auto;
`

const Description = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.variants.neutralLight3};
  border-bottom: 0;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 2rem;
  margin: 0 10vw;
  padding: 3.75rem 1.5rem;
  text-align: center;
`

const Form = styled.form`
  width: 100%;
  max-width: 70.25rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 1.25rem 1.5rem 5rem;
  position: relative;

  @media (max-width: 767px) {
    padding: 3rem 1.5rem;
  }
`

const Wrapper = styled(FlexBox)`
  max-width: 42.125rem;
  margin: auto;

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    input {
      width: 100%;
      height: 3rem;
      background-image: url('/calendar.svg');
      background-position: right center;
      background-repeat: no-repeat;
      border-bottom: 2px solid
        ${({ theme }) => theme.colors.variants.neutralLight1};
      color: ${({ theme }) => theme.colors.variants.neutralDark4};
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 0.875rem;
      &.error {
        border-color: ${({ theme }) => theme.colors.variants.dangerLight1};
      }
    }
  }

  .react-datepicker {
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    background-color: ${({ theme }) => theme.colors.variants.neutralLight4};
    color: ${({ theme }) => theme.colors.variants.neutralDark4};
  }

  .react-datepicker__portal {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${rgba(theme.colors.variants.neutralDark1, 0.7)};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day:hover,
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.variants.primaryDark1};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  .react-datepicker__day:focus {
    background-color: ${({ theme }) =>
      theme.colors.variants.primaryDark1} !important;
    color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
  }

  .react-datepicker__day--today {
    &:after {
      background: ${({ theme }) => theme.colors.variants.primaryLight1};
    }
  }

  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colors.variants.neutralLight2} !important;
  }
`

const Field = styled.div`
  width: calc(50% - 1.875rem);
  margin-top: 3.75rem;
  &.full-width {
    width: 100%;
  }

  @media (max-width: 767px) {
    width: calc(50% - 0.75rem);
  }
`

const Label = styled.label`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
`
